import { MenuLayout } from '@templates/Menu/MenuLayout.ui';
import { GetServerSideProps } from 'next';
import { QueryClient } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import { createMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { getMerchantThemeSSR } from '@domain/merchants/functions/getMerchantThemeSSR';
import { getFeatureFlags, ldCookieOptions } from '@olo-web/services/launchDarkly';
import nookies from 'nookies';
import { ECookies } from '@olo-web/types/enums';
import {
  configureMerchantData,
  configureMerchantGroupData,
} from '@domain/merchants/functions/configureMerchantData';
import { v4 as uuid } from 'uuid';
import { getLDUserValuesFromCtx } from '@olo-web/utils';
import { getMerchant } from '@olo-web/services/emagine/getMerchant';
import { getMerchantGroup } from '@olo-web/services/emagine/getMerchantGroup';
import { getMenu } from '@olo-web/services/emagine/getMenu';
import { roundDownDateTime } from '@olo-web/domain/orders/hooks/useRoundedDownOrderDateTime';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { getMerchantIdentification } from '@olo-web/services/theseus/getMerchantIdentification';
import { EAIRecommendationVariations } from '@olo-web/types/enums/GoogleAISuggestionsVariations.enum';
import { getRecommendedItems } from '@olo-web/services/recommendations/getRecommendedItems';
import { validateBlid } from '@olo-web/utils/common/functions/validateBlid';
import { setGoogleConversionCookie } from '@olo-web/utils/common/functions/setGoogleConversionCookie';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    const {
      res: { statusCode },
    } = ctx;
    const { merchantId: merchantIdFromSlug, ip } = getLDUserValuesFromCtx(ctx);
    // Extract rwg_token from query params and set to cookies
    setGoogleConversionCookie(ctx, merchantIdFromSlug as string);

    if (!merchantIdFromSlug) return { notFound: true };
    const cookie = nookies.get(ctx);
    const userKey = cookie[ECookies.ldUserKey] || uuid();
    if (!cookie[ECookies.ldUserKey]) nookies.set(ctx, ECookies.ldUserKey, userKey, ldCookieOptions);
    const customerId = cookie[ECookies.userId] || Math.floor(Math.random() * 1000000).toString();
    if (!cookie[ECookies.userId]) nookies.set(ctx, ECookies.userId, customerId, ldCookieOptions);
    const [featureFlags, merchantRes, merchantGroupRes, activeBlidSearchRes] = await Promise.all([
      getFeatureFlags(userKey, merchantIdFromSlug as string, ip as string),
      getMerchant(merchantIdFromSlug),
      getMerchantGroup(merchantIdFromSlug as string),
      getMerchantIdentification({ id: merchantIdFromSlug as string }), // Adding this to support the recommendations feature which only works with merchantId
    ]);

    const merchant = configureMerchantData(
      merchantRes?.data,
      featureFlags?.disallowDoorDashDriveOrders
    );
    const merchantGroup = configureMerchantGroupData(
      merchantGroupRes?.data,
      featureFlags?.disallowDoorDashDriveOrders
    );
    // Fetch the merchant and if ordering is unavailable redirect to ordering-unavailable
    if (!merchant?.isOrderingAvailable || statusCode === 500) {
      return {
        redirect: {
          destination: `${createMerchantPath({
            merchantId: merchantIdFromSlug as string,
            merchant,
            groupId: merchantGroup?.id,
          })}/ordering-unavailable`,
          permanent: false,
        },
      };
    }

    const queryClient = new QueryClient();

    const promises = [
      queryClient.prefetchQuery(
        ['merchant', merchantIdFromSlug],
        // @ts-ignore
        () => merchant
      ),
      queryClient.prefetchQuery(
        ['merchant-group', merchantIdFromSlug],
        // @ts-ignore
        () => merchantGroup
      ),
      queryClient.prefetchQuery(
        ['feature-flags', userKey],
        //@ts-ignore
        () => featureFlags
      ),
      queryClient.prefetchQuery(
        ['merchant-identification', merchantIdFromSlug],
        //@ts-ignore
        () => activeBlidSearchRes.data
      ),
    ];
    //Prioritize the merchant id from the blid search if it exists
    const merchantIdForRecommendations =
      activeBlidSearchRes?.data?.merchant_id ||
      (validateBlid(merchantIdFromSlug) ? '' : merchantIdFromSlug);

    if (merchantIdForRecommendations) {
      const isGoogleAIABTestingEnabled = isFeatureEnabledForMerchant({
        featureEnabled: featureFlags?.GoogleAISuggestionsAllowDenyListing?.on,
        allowList: featureFlags?.GoogleAISuggestionsAllowDenyListing?.allow,
        denyList: featureFlags?.GoogleAISuggestionsAllowDenyListing?.deny,
        merchantId: merchantIdFromSlug,
      });
      const recommendationsFeatureFlagEnabled =
        isGoogleAIABTestingEnabled &&
        (featureFlags?.GoogleAISuggestions === EAIRecommendationVariations.ALL ||
          featureFlags?.GoogleAISuggestions === EAIRecommendationVariations.MENU);
      if (recommendationsFeatureFlagEnabled) {
        promises.push(
          queryClient.prefetchQuery(['item-recommendations-for-you', customerId], async () => {
            const { data } = await getRecommendedItems({
              user_id: customerId,
              merchant_id: merchantIdForRecommendations,
              limit: 12,
            });
            return data?.recommendations;
          })
        );
      }
    }

    if (!featureFlags?.hideMerchantTheming) {
      promises.push(
        queryClient.prefetchQuery(
          ['merchant-theme', merchantIdFromSlug],
          // @ts-ignore
          () => getMerchantThemeSSR(merchantIdFromSlug)
        )
      );
    }

    if (merchant?.defaultOrderTypeId) {
      const roundedDownOrderDateTime = roundDownDateTime(
        merchant?.defaultOrderType?.asapOrderDateTime
      );

      promises.push(
        queryClient.prefetchQuery(
          ['menu', merchant?.merchantId, merchant?.defaultOrderTypeId, roundedDownOrderDateTime],
          async () => {
            const { data } = await getMenu(
              merchant?.merchantId,
              merchant?.defaultOrderTypeId,
              merchant?.defaultOrderType?.asapOrderDateTime,
              false
            );
            return data;
          }
        )
      );
    }

    await Promise.all(promises);

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        globalUIState: {
          ldUserKey: userKey,
          customerId: customerId,
        },
      },
      // every 6 hours (in secs)
    };
  } catch (e) {
    if (e?.response?.status === 404) {
      return {
        notFound: true,
      };
    }

    throw e;
  }
};

export default MenuLayout;
